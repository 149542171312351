<template>
  <div>
    <div class="bg-cl-secondary py35 pl20">
      <div class="container">
        <h1 class="fs-big">
          Testing page
        </h1>
      </div>
    </div>

    <div class="container pt45 pb70">
      <div class="row pl20 pr20 pt0">
        <div class="col-md-12">
          <div class="map-wrapper">
            <partner-map size="fill" :markers="partnerMarkers" :on-marker-click="onMarkerClick" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from 'theme/components/core/Map';

export default {
  components: {
    'partner-map': Map
  },
  data () {
    return {
      partnerMarkers: [
        {
          lat: 50.1090641,
          lng: 14.452747500000001,
          icon: {
            url: 'https://anyrent.cz/assets/apple-touch-icon.png',
            width: 32,
            height: 32
          }
        },
        {
          lat: 50.1050531,
          lng: 14.452747300000001,
          icon: {
            url: 'https://anyrent.cz/assets/apple-touch-icon.png',
            width: 32,
            height: 32
          }
        }
      ]
    }
  },
  methods: {
    onMarkerClick (marker) {
      console.log('clicked on marker', marker);
    }
  }
}
</script>

<style lang="scss">
.map-wrapper {
  width: 100%;
  height: 800px;
  position: relative;
}
</style>
