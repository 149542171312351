
export const mapBoxProps = {
  size: {
    type: String,
    default: 'fixed',
    required: true
  },
  width: {
    type: String
  },
  height: {
    type: String
  },
  coordinates: {
    type: Object,
    default: null
  },
  markers: {
    type: Array,
    default: () => []
  },
  zoom: {
    type: Number,
    default: 13
  },
  clustering: {
    type: Boolean,
    default: true
  },
  focusedMarker: {
    type: Object,
    default: null
  },
  onMarkerClick: {
    type: Function,
    default: () => {}
  }
};
