<template>
  <no-ssr>
    <map-box v-bind="$props" />
  </no-ssr>
</template>

<script>
import NoSSR from 'vue-no-ssr';
import LoadingMap from './map/LoadingMap';
import { mapBoxProps } from './map/mapBoxProps';

// Component used as a workaround to disable server-side rendering of Map
// and start initializing map on client side only
export default {
  name: 'Map',
  components: {
    'map-box': () => process.browser ? import('theme/components/core/map/MapBox') : LoadingMap,
    'no-ssr': NoSSR
  },
  props: mapBoxProps
}
</script>
